const IntroSection = () => {
    return (
        /*<section id="intro">
            <h2>Welcome to Our Media Agency</h2>
            <p>We provide professional dubbing services in multiple languages, AI and human dubbing, and more.</p>
        </section>*/

        <section id="intro">
            <p className="name">Welcome to
                <span>Dubbi Go</span>
            </p>
            <h2>
                {/*We bring your content to life in any language!*/}
                Your content, any language
            </h2>
            <p>
                At Dubbi Go, we provide dubbing, subtitling, localization, and full channel/page dubbing management to expand your content’s reach.
            </p>
            <p>
                Check out our <a href="#services">services</a> or see our latest <a href="#projects">projects</a> to learn more about what we do!
            </p>
        </section>
    );
};

export default IntroSection;
