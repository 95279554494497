import React from 'react';
import '../App.css'; // Import your CSS file here
import { Link } from "react-router-dom";



const NavBar = () => {
    return (
        <nav>
            <ul>
                <li>
                    <h1><a href="index.html">
                        <span className="fa-solid fa-microphone-lines"></span>
                        <span>DubbiGo</span>
                    </a></h1>
                </li>
                <li>
                    <a href="#services">Services</a>
                </li>
                <li>
                    <a href="#team">Team</a>
                </li>
                {/*<li>*/}
                {/*    <Link to="/price">Pricing</Link> /!* Navigate to the pricing component *!/*/}
                {/*</li>*/}
                <li>
                    <a href="#details">Contact</a>
                </li>
                <li>
                    <a href="#faq">FAQ</a>
                </li>
                <li>
                    <a target="_blank" href="https://www.instagram.com/dubbigo.ai">
                        {/*<span className="fa-brands fa-linkedin icon"></span>*/}
                        <span className="fa-brands fa-instagram icon"></span>
                        <span className="textHide">LinkedIn</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://www.youtube.com/@dubbigo">
                        <span className="fa-brands fa-youtube icon"></span>
                        <span className="textHide">YouTube</span>
                    </a>
                </li>
                <li>
                    <a className="button" href="#details">Get a Quote</a>
                </li>
            </ul>
        </nav>


    );
};

export default NavBar;
