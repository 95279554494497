import React, {useState, useEffect} from 'react';
import {Info} from 'lucide-react';
import '@fortawesome/fontawesome-free/css/all.min.css';


const DubbingServiceForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        channelName: '',
        email: '',
        phoneNumber: '',
        projectDetails: '',
        rightsSharingOption: 'client',
        languages: ['English'],
        dubbingType: 'AI',
        videoLength: 1,
        addSubtitles: false,
        channelManagement: false,
        managedPlatforms: {
            facebook: false,
            instagram: false,
            youtube: false
        },
        price: {
            perVideo: 0,
            channelManagement: 0
        }
    });
    const [showServices, setShowServices] = useState(false);
    const [price, setPrice] = useState({
        perVideo: 0,
        channelManagement: 0
    });

    const languageOptions = [
        'English', 'Spanish', 'French', 'German', 'Hindi', 'Chinese', 'Japanese', 'Korean', 'Arabic', 'Portuguese',
        'Vietnamese', 'Russian', 'Indonesian', 'Turkish', 'Bangla', 'Thai', 'Marathi', "Telugu"
    ];

    const managementServices = [
        'SEO Optimization', 'Content Posting', 'Ad Collaborations(10% cut)',
        'Localized Advertising', 'Analytics and Reporting', 'Community Management'
    ];

    const handleInputChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleLanguageToggle = (lang) => {
        setFormData(prev => ({
            ...prev,
            languages: prev.languages.includes(lang)
                ? prev.languages.filter(l => l !== lang)
                : [...prev.languages, lang]
        }));
    };

    const handlePlatformToggle = (platform) => {
        setFormData(prev => ({
            ...prev,
            managedPlatforms: {
                ...prev.managedPlatforms,
                [platform]: !prev.managedPlatforms[platform]
            }
        }));
    };

    useEffect(() => {
        let perVideoPrice = 0;
        let channelManagementPrice = 0;

        if (formData.rightsSharingOption === 'client') {
            // Calculate per video price
            const basePrice = formData.dubbingType === 'AI' ? 1 : 10;
            perVideoPrice = formData.languages.length * basePrice * formData.videoLength;

            if (formData.addSubtitles) {
                perVideoPrice += formData.languages.length * 0.5 * formData.videoLength;
            }

            // Calculate channel management price
            if (formData.channelManagement) {
                const platformCount = Object.values(formData.managedPlatforms).filter(Boolean).length;
                // channelManagementPrice = 25 * platformCount;
                // channelManagementPrice = 25 * platformCount + 25 * formData.languages.length;
                // channelManagementPrice = 10 * platformCount * formData.languages.length +  25 * formData.languages.length ;
                channelManagementPrice = 10 * platformCount * formData.languages.length  ;
            }
        } else {
            // For rights sharing option, set prices to 0
            perVideoPrice = 0;
            channelManagementPrice = 0;
        }

        setPrice({
            perVideo: perVideoPrice,
            channelManagement: channelManagementPrice
        });
    }, [formData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://dubbing-agency.onrender.com/api/form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({...formData, price}),
            });
            const data = await response.json();
            alert(data.message || 'Form submitted successfully');
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred while submitting the form. Please try again.');
        }
    };

    return (
        <div id={'details'}
             className="bg-gradient-to-br from-[#0d314b] via-[#193a55] to-[#1c4b65] min-h-screen py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
                <div className="text-center mb-8">
                    <h1 className="text-4xl font-bold text-white mb-2">Professional Dubbing Services</h1>
                    <p className="text-xl text-gray-200">Customize Your Project and Get an Instant Quote</p>
                </div>

                <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                    <div className="bg-gradient-to-r from-[#4b0d49] to-[#e310cb] p-6 text-white">
                        <h2 className="text-2xl font-semibold">Your Dubbing Project</h2>
                        <p className="mt-1 text-blue-100">Fill in the details below to get started</p>
                    </div>

                    <form className="p-6 space-y-6" onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                            <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Full
                                    Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                                    placeholder="John Doe"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="channelName"
                                       className="block text-sm font-medium text-gray-700 mb-1">Channel Link</label>
                                <input
                                    type="text"
                                    id="channelName"
                                    name="channelName"
                                    value={formData.channelName}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                                    placeholder="My Awesome Channel"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email
                                    Address</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                                    placeholder="john@example.com"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="phoneNumber"
                                       className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
                                <input
                                    type="tel"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                                    placeholder="+1 (123) 456-7890"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="projectDetails"
                                   className="block text-sm font-medium text-gray-700 mb-1">Project Details</label>
                            <textarea
                                id="projectDetails"
                                name="projectDetails"
                                value={formData.projectDetails}
                                onChange={handleInputChange}
                                rows={4}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-gray-900"
                                placeholder="Tell us more about your project, specific requirements, or any questions you have..."
                            />
                        </div>


                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">Project Option</label>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div
                                    className={`relative overflow-hidden rounded-xl border-2 transition-all duration-300 ${
                                        formData.rightsSharingOption === 'client' ? 'border-[#e310cb] bg-purple-50' : 'border-gray-200 bg-white'
                                    }`}
                                >
                                    <label className="flex h-full cursor-pointer p-1 ml-2 mb-5">
                                        <input
                                            type="radio"
                                            name="rightsSharingOption"
                                            value="client"
                                            checked={formData.rightsSharingOption === 'client'}
                                            onChange={handleInputChange}
                                            className="sr-only"
                                        />
                                        <div className="flex items-center">
                                            <div className={`w-10 h-10 rounded-full flex items-center justify-center ${
                                                formData.rightsSharingOption === 'client' ? 'bg-[#e310cb]' : 'bg-gray-100'
                                            }`}>
                                                <i className={`fas fa-user-circle text-xl ${formData.rightsSharingOption === 'client' ? 'text-white' : 'text-gray-600'}`}></i>
                                            </div>
                                            <div className="ml-3 flex-grow">
                                                <div className="font-medium text-gray-900">Standard Service</div>
                                                <div className="text-sm text-gray-500">You retain all rights and manage
                                                    your channels
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div
                                    className={`relative overflow-hidden rounded-xl border-2 transition-all duration-300 ${
                                        formData.rightsSharingOption === 'agency' ? 'border-[#e310cb] bg-purple-50' : 'border-gray-200 bg-white'
                                    }`}
                                >
                                    <label className="flex h-full cursor-pointer p-1 ml-2 mb-5">
                                        <input
                                            type="radio"
                                            name="rightsSharingOption"
                                            value="agency"
                                            checked={formData.rightsSharingOption === 'agency'}
                                            onChange={handleInputChange}
                                            className="sr-only"
                                        />
                                        <div className="flex items-center">
                                            <div className={`w-10 h-10 rounded-full flex items-center justify-center ${
                                                formData.rightsSharingOption === 'agency' ? 'bg-[#e310cb]' : 'bg-gray-100'
                                            }`}>
                                                <i className={`fas fa-handshake text-xl ${formData.rightsSharingOption === 'agency' ? 'text-white' : 'text-gray-600'}`}></i>
                                            </div>
                                            <div className="ml-3 flex-grow">
                                                <div className="font-medium text-gray-900">Rights Sharing (70/30
                                                    Split)
                                                </div>
                                                <div className="text-sm text-gray-500">We manage channels and share
                                                    revenue
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            {formData.rightsSharingOption === 'client' && (
                                <>
                                    {/* Existing fields for standard service */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2 mt-4">Target Languages</label>
                                        <div className="flex flex-wrap gap-2">
                                            {languageOptions.map(lang => (
                                                <button
                                                    key={lang}
                                                    type="button"
                                                    onClick={() => handleLanguageToggle(lang)}
                                                    className={`px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200 ${
                                                        formData.languages.includes(lang)
                                                            ? 'bg-[#e310cb] text-white'
                                                            : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                                                    }`}
                                                >
                                                    {lang}
                                                </button>
                                            ))}
                                        </div>
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1 mt-4">Dubbing Type</label>
                                        <div className="flex space-x-4">
                                            {['AI', 'Human'].map(type => (
                                                <label key={type} className="inline-flex items-center">
                                                    <input
                                                        type="radio"
                                                        name="dubbingType"
                                                        value={type}
                                                        checked={formData.dubbingType === type}
                                                        onChange={handleInputChange}
                                                        className="form-radio h-4 w-4 text-indigo-600"
                                                    />
                                                    <span className="ml-2 text-gray-700">{type} Dubbing</span>
                                                </label>
                                            ))}
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="videoLength" className="block text-sm font-medium text-gray-700 mb-1 mt-4">
                                            Video Length: <span className="font-bold text-indigo-600">{formData.videoLength} minutes</span>
                                        </label>
                                        <input
                                            type="range"
                                            id="videoLength"
                                            name="videoLength"
                                            min="1"
                                            max="60"
                                            value={formData.videoLength}
                                            onChange={handleInputChange}
                                            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                        />
                                        <div className="flex justify-between text-xs text-gray-500 ">
                                            <span>1 min</span>
                                            <span>30 min</span>
                                            <span>60 min</span>
                                        </div>
                                    </div>

                                    {/* Additional Services section */}
                                    <div className="space-y-4 ">
                                        <div className="flex justify-between items-center">
                                            <h3 className="text-lg font-semibold text-gray-900 mt-4">Additional Services</h3>
                                            <div className="text-sm text-gray-500">Select to customize your package</div>
                                        </div>

                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            {/* Subtitles Card */}
                                            <div
                                                className={`relative overflow-hidden rounded-xl border-2 transition-all duration-300 ${
                                                    formData.addSubtitles ? 'border-[#e310cb] bg-purple-50' : 'border-gray-200 bg-white'
                                                }`}>
                                                <label className="flex h-full cursor-pointer p-4">
                                                    <input
                                                        type="checkbox"
                                                        checked={formData.addSubtitles}
                                                        onChange={() => setFormData(prev => ({
                                                            ...prev,
                                                            addSubtitles: !prev.addSubtitles
                                                        }))}
                                                        className="sr-only"
                                                    />
                                                    <div className="flex flex-col flex-grow">
                                                        <div className="flex items-center mb-2">
                                                            <div className={`w-8 h-8 rounded-lg flex items-center justify-center ${
                                                                formData.addSubtitles ? 'bg-[#e310cb]' : 'bg-gray-100'
                                                            }`}>
                                                                <i className={`fas fa-closed-captioning ${formData.addSubtitles ? 'text-white' : 'text-gray-600'}`}></i>
                                                            </div>
                                                            <div className="ml-3 flex-grow">
                                                                <h4 className="text-base font-medium text-gray-900">Subtitles</h4>
                                                                <p className="text-sm text-gray-500">Multi-language support</p>
                                                            </div>
                                                            <div className="text-right">
                                                                <div className="text-[#e310cb] font-semibold">+$0.5</div>
                                                                <div className="text-xs text-gray-500">per language</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>

                                            {/* Channel Management Card */}
                                            <div
                                                className={`relative overflow-hidden rounded-xl border-2 transition-all duration-300 ${
                                                    formData.channelManagement ? 'border-[#e310cb] bg-purple-50' : 'border-gray-200 bg-white'
                                                }`}>
                                                <label className="flex h-full cursor-pointer p-4">
                                                    <input
                                                        type="checkbox"
                                                        checked={formData.channelManagement}
                                                        onChange={() => setFormData(prev => ({
                                                            ...prev,
                                                            channelManagement: !prev.channelManagement
                                                        }))}
                                                        className="sr-only"
                                                    />
                                                    <div className="flex flex-col flex-grow">
                                                        <div className="flex items-center mb-2">
                                                            <div className={`w-8 h-8 rounded-lg flex items-center justify-center ${
                                                                formData.channelManagement ? 'bg-[#e310cb]' : 'bg-gray-100'
                                                            }`}>
                                                                <i className={`fas fa-tasks ${formData.channelManagement ? 'text-white' : 'text-gray-600'}`}></i>
                                                            </div>
                                                            <div className="ml-3 flex-grow">
                                                                <div className="flex items-center gap-2">
                                                                    <h4 className="text-base font-medium text-gray-900">Channel Management</h4>
                                                                    <button
                                                                        type="button"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setShowServices(!showServices);
                                                                        }}
                                                                        className="text-gray-400 hover:text-[#e310cb] transition-colors"
                                                                    >
                                                                        <Info size={14}/>
                                                                    </button>
                                                                </div>
                                                                <p className="text-sm text-gray-500">Expert social media handling</p>
                                                            </div>
                                                            <div className="text-right">
                                                                <div className="text-[#e310cb] font-semibold">$10</div>
                                                                <div className="text-xs text-gray-500">per platform</div>
                                                            </div>
                                                        </div>

                                                        {/* Services Info */}
                                                        {showServices && (
                                                            <div className="mt-3 grid grid-cols-2 gap-2 text-sm bg-white rounded-lg p-3 border border-purple-100">
                                                                {managementServices.map((service, index) => (
                                                                    <div key={index} className="flex items-center text-gray-600">
                                                                        <div className="w-1 h-1 bg-[#e310cb] rounded-full mr-2"></div>
                                                                        {service}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}

                                                        {/* Platform Selection */}
                                                        {formData.channelManagement && (
                                                            <div className="mt-3">
                                                                <div className="text-xs font-medium text-gray-500 mb-2">Select platforms:</div>
                                                                <div className="flex flex-wrap gap-2">
                                                                    {Object.keys(formData.managedPlatforms).map((platform) => (
                                                                        <button
                                                                            key={platform}
                                                                            type="button"
                                                                            onClick={() => handlePlatformToggle(platform)}
                                                                            className={`inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium transition-colors duration-200 ${
                                                                                formData.managedPlatforms[platform]
                                                                                    ? 'bg-[#e310cb] text-white'
                                                                                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                                                                            }`}
                                                                        >
                                                                            <div className={`w-4 h-4 flex items-center justify-center mr-1.5 ${
                                                                                formData.managedPlatforms[platform] ? 'text-white' : 'text-gray-600'
                                                                            }`}>
                                                                                <i className={`fab fa-${platform.toLowerCase()}`}></i>
                                                                            </div>
                                                                            <span>{platform.charAt(0).toUpperCase() + platform.slice(1).toLowerCase()}</span>
                                                                        </button>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {formData.rightsSharingOption === 'agency' && (
                                <div className="bg-white border border-gray-200 hover:border-purple-400 rounded-lg p-4 mt-4 transition-all duration-300 hover:shadow-lg">
                                    {/* Modern Header with Icon */}
                                    <div className="flex items-center gap-3 mb-4">
                                        <div className="w-10 h-10 rounded-full bg-gradient-to-r from-[#4b0d49] to-[#e310cb] flex items-center justify-center shadow-md">
                                            <i className="fas fa-handshake text-white text-lg"></i>
                                        </div>
                                        <div>
                                            <h3 className="text-lg font-semibold text-gray-800 hover:text-[#e310cb] transition-colors duration-200">Rights Sharing Agreement</h3>
                                            <p className="text-sm text-gray-500">70/30 Revenue Split Partnership</p>
                                        </div>
                                    </div>

                                    {/* Professional Tools Banner */}
                                    <div className="bg-purple-50 hover:bg-purple-100 rounded-lg p-3 mb-4 transition-colors duration-200">
                                        <div className="flex items-center gap-2 text-sm text-gray-700">
                                            <i className="fas fa-tools text-[#e310cb]"></i>
                                            <span className="font-medium">Professional tools we use:</span>
                                            <span className="text-gray-600">Workspace • Google voice • Viralfindr • Zeemo • TubeBuddy • Chatgpt Plus</span>
                                        </div>
                                    </div>

                                    {/* Agreement Points */}
                                    <ul className="space-y-3">
                                        {[
                                            "We will take full responsibility for creating social media channels, managing dubbing content, uploading, engagement, ads, and collaborations on your behalf.",
                                            "All revenue generated from monetization, ad collaborations, sponsorships, subscription, and any other income from the content managed by us will be split as follows:",
                                            "70% for you and 30% for us when the collaboration is arranged by us or appears on the dubbed channel.",
                                            "If a collaboration is initiated by you or appears on your original channel, the revenue from that specific video remains yours entirely.",
                                        ].map((text, index) => (
                                            <li key={index} className="flex items-start gap-2 group hover:bg-purple-50 p-2 rounded-lg transition-colors duration-200">
                                                <div className="w-5 h-5 rounded-full bg-gradient-to-r from-[#4b0d49] to-[#e310cb] flex items-center justify-center flex-shrink-0 mt-0.5 group-hover:shadow-md transition-shadow duration-200">
                                                    <i className="fas fa-check text-white text-xs"></i>
                                                </div>
                                                <span className="text-sm text-gray-700 group-hover:text-gray-900 transition-colors duration-200">{text}</span>
                                            </li>
                                        ))}
                                    </ul>

                                    {/* Footer Note */}
                                    <div className="mt-4 pt-3 border-t border-gray-100 group hover:border-purple-200 transition-colors duration-200">
                                        <p className="text-sm text-gray-500 flex items-center gap-2 group-hover:text-gray-700 transition-colors duration-200">
                                            <i className="fas fa-shield-alt text-[#e310cb] group-hover:scale-110 transition-transform duration-200"></i>
                                            All professional tools and services included at no extra cost
                                        </p>
                                    </div>
                                </div>
                            )}

                            <div className="bg-gradient-to-r from-[#4b0d49] to-[#e310cb] p-4 rounded-lg shadow-inner mt-4">
                                <h3 className="text-2xl font-bold text-white mb-2">Estimated Price / Revenue</h3>
                                {formData.rightsSharingOption === 'client' ? (
                                    <div className="flex justify-between items-center mb-2">
                                        <div className="w-1/2">
                                            <p className="text-blue-100 text-lg font-semibold">Per Video:</p>
                                            <p className="text-white text-3xl font-bold">${price.perVideo.toLocaleString()}</p>
                                        </div>
                                        {formData.channelManagement && (
                                            <div className="w-1/2 text-right">
                                                <p className="text-blue-100 text-lg font-semibold">Monthly Channel Management:</p>
                                                <p className="text-white text-3xl font-bold">${price.channelManagement.toLocaleString()}</p>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <p className="text-white text-xl">No upfront cost. 70% revenue share for you.</p>
                                )}
                                <p className="text-blue-100 text-sm mt-2">
                                    {formData.rightsSharingOption === 'client'
                                        ? "Final price may vary based on project specifics"
                                        : "Agreement terms may be adjusted with mutual consent from both parties."}
                                </p>
                            </div>
                        </div>

                        <div className="flex items-center justify-center">
                            <button
                                type="submit"
                                className="w-full flex justify-between items-center py-4 px-6 border border-transparent rounded-lg shadow-md text-base font-semibold text-white bg-[#e310cb] hover:bg-[#d00cb8] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#e310cb] transition-all duration-300"
                            >
                                <span>Submit Your Project Request</span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd"
                                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                          clipRule="evenodd"/>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default DubbingServiceForm;