// import './App.css';
import NavBar from "./component/NavBar";
import Footer from "./component/Footer";
import IntroSection from "./component/Intro";
import ServicesSection from "./component/Services";
import TeamSection from "./component/Team";
import DubbingServiceForm from "./component/DubbingServiceForm";
import Faq from "./component/Faq";
import BrandSection from "./component/BrandSection";


function App() {
  return (
      <div className="App">
          <NavBar/>
          <IntroSection/>

            <BrandSection/>
              <ServicesSection/>
              <TeamSection/>

              <DubbingServiceForm/>
              <Faq/>
              <Footer/>


      </div>

  );
}

export default App;
