import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <h2>Dubbi Go</h2>
                    <p>Professional Multilingual Dubbing Services</p>
                </div>

               {/* <div className="footer-section">
                    <h3>Our Services</h3>
                    <ul>
                        <li>Social Media Content Dubbing</li>
                        <li>AI Voice Cloning</li>
                        <li>Subtitling & Captioning</li>
                        <li>Channel Management</li>
                    </ul>
                </div>*/}

                <div className="footer-section">
                    <h3>Contact Us</h3>
                    <p>Email: info@dubbigo.com</p>
                    {/*<p>Phone: +91 </p>*/}
                    <p>Bangalore, India</p>
                </div>

                <div className="footer-section">
                    <h3>Follow Us</h3>
                    <div className="social-links">
                        {/*<a href="https://www.linkedin.com/company/dubbigo" aria-label="LinkedIn">
                            <i className="fab fa-linkedin"></i>
                        </a>*/}
                        <a href="https://www.youtube.com/@dubbigo" aria-label="YouTube">
                            <i className="fab fa-youtube"></i>
                        </a>
                        <a href="https://www.instagram.com/dubbigo.ai" aria-label="Instagram">
                            <i className="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 Dubbi Go. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;