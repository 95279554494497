import React from 'react';



const brands = [
    { id: 1, name: 'Pepsi', image: 'https://yt3.ggpht.com/yT1uSZBRfpHtAreeWGQU44d0DmqeoA0QnfM_-bJ8XJbdVhVASKqxYwDy-J0RPaY8TXoq_DDzBmhx=s600-rw-nd-v1' },
    { id: 2, name: 'MOSTBET', image: 'https://mostbet1.in/wp-content/uploads/2022/09/mosbet-logo.png' },
    { id: 3, name: 'Loreal', image: 'https://yt3.ggpht.com/uXX9huKn2Pc4-Q7gXXF6nvG8twDs0wHP9zEUESXnLHggs5szTATazkCy2Poe5iBbXBdi8nGhrRBI7A=s640-c-fcrop64=1,40000000bfffffff-rw-nd-v1' },
    { id: 4, name: 'Lays', image: 'https://www.lays.com/sites/lays.com/themes/lays/img/logo-lays.png?v=2' },
    { id: 5, name: 'Dot & Key', image: 'https://yt3.ggpht.com/2N2ALKtkddg_ztG1ClFO7XcjApu_kDDnDvXzdeM3pUNDFnrIk_YxtmeMQhDj_NhxZSv2sNZ8nbx_-cQ=s350-c-fcrop64=1,00000000ffffffff-rw-nd-v1' },
    { id: 6, name: 'Hipi', image: 'https://www.hipi.co.in/icons/Logo_hipi.png' },
    { id: 7, name: 'Nykaa', image: 'https://cdn.worldvectorlogo.com/logos/nykaa-1.svg' },
    { id: 8, name: 'Thums up', image: 'https://www.coca-cola.com/content/dam/onexp/in/en/home-page-test-img/logos1/logo-svg/TU%20Logo.svg' }
];

const BrandSection = () => {
    return (
        <section className="bg-gradient-to-r from-[#4b0d49] to-[#e310cb] text-white py-16">
            <div className="max-w-7xl mx-auto px-4 ">
                <div className="text-center mb-12">
                    <h2 className="text-4xl font-bold mb-6">
                        Our Sponsorship and Ad Collaborators
                    </h2>
                    <p className="text-xl max-w-2xl mx-auto text-blue-100">
                        15+ leading brands and sponsors trust us to deliver high-quality content and brand collaborations.
                    </p>
                </div>

                <div className="grid grid-cols-2 md:grid-cols-8 gap-10 md:gap-14">
                    {brands.map((brand) => (
                        <div
                            key={brand.id}
                            className="flex items-center justify-center transition-transform duration-300 hover:scale-105"
                        >
                            <img
                                src={brand.image}
                                alt={brand.name}
                                width={160}
                                height={60}
                                className="w-40 h-25 object-contain opacity-80 hover:opacity-100"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default BrandSection;