import React from 'react';
import '../App.css';

const ServicesSection = () => {
    const services = [
        {
            icon: 'fa-solid fa-language',
            title: "Multilingual Dubbing",
            description: "AI and human voice dubbing in various languages, expanding your content's reach."
        },
        {
            icon: 'fa-solid fa-closed-captioning',
            title: "Subtitling & Captioning",
            description: "Multilingual subtitles and captions, including for hearing impaired audiences."
        },
        {
            icon: 'fa-solid fa-search',
            title: "SEO Optimization",
            description: "Boost your content visibility with targeted keywords, meta descriptions, and search-friendly structuring."
        },
        {
            icon: 'fa-solid fa-calendar-check',
            title: "Content Scheduling",
            description: "Strategic planning and automated posting of your content across multiple platforms for maximum engagement."
        },
        {
            icon: 'fa-solid fa-handshake',
            title: "Ad Collaborations",
            description: "Connect with brands and manage sponsored content opportunities to monetize your channel effectively."
        },
        {
            icon: 'fa-solid fa-microphone-alt',
            title: "AI Voice Cloning",
            description: "Preserve your voice across languages with advanced AI voice cloning technology."
        },
        {
            icon: 'fa-solid fa-ad',
            title: "Localized Advertising",
            description: "Region-specific ads and product placements for targeted marketing campaigns."
        },
        {
            icon: 'fa-solid fa-globe',
            title: "Cultural Adaptation",
            description: "Tailor your content for different cultures, ensuring relevance and sensitivity."
        }
    ];

    return (
        <section id="services" className="services-section section-dkblue">
            <div className="container">
                <h2 className="section-title">Our Services</h2>
                <div className="services-grid">
                    {services.map((service, index) => (
                        <div key={index} className="service-item">
                            <div className="service-icon">
                                <i className={service.icon}></i>
                            </div>
                            <h3 className="service-title">{service.title}</h3>
                            <p className="service-description">{service.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ServicesSection;