import React, { useState } from 'react';
import ashish from '../image/ashish.png';
import gourav from '../image/gourav.jpeg';
import atharv from '../image/atharv.jpg';

// Default placeholder image for members without a profile image
const defaultImage = 'https://via.placeholder.com/64'; // Placeholder for missing images

const teamMembers = [
    { id: 1, name: 'Gourav Chouhan', role: 'Project Manager', location: 'Indore', image: gourav },
    { id: 2, name: 'Atharv Barkiya', role: 'Audio Engineer', location: 'Nepal', image: atharv },
    { id: 3, name: 'Rithik Verma', role: 'Video Editor', location: 'Texas', image: 'https://media.licdn.com/dms/image/v2/D4D03AQHwc7mfDuh0OQ/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1721245236678?e=1733961600&v=beta&t=-qt609__N700TqzJZP0QecNtnb_4Wxi3TDUFekSffo4' },
    { id: 4, name: 'Salman Lala', role: 'Language Specialist', location: 'Punjab', image: 'https://pbs.twimg.com/profile_images/1572232043081404416/wsDtVwr2_400x400.jpg' }, // Default image
    { id: 5, name: 'Ashish Patidar', role: 'Content Strategist', location: 'Bangalore', image: ashish },
    { id: 6, name: 'Rithik Verma', role: 'Social Media Manager', location: 'Texas', image: 'https://media.licdn.com/dms/image/v2/D4D03AQHwc7mfDuh0OQ/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1721245236678?e=1733961600&v=beta&t=-qt609__N700TqzJZP0QecNtnb_4Wxi3TDUFekSffo4' },
    { id: 7, name: 'Sami', role: 'Script Translator', location: 'Lima', image: defaultImage },
    { id: 8, name: 'Gourav Chouhan', role: 'AI Specialist', location: 'India', image: 'https://media.licdn.com/dms/image/v2/D4D03AQFsptGGB8_eHQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1694016498903?e=1733356800&v=beta&t=fOQIbvvJfai60luNYiY-dRoWQMfuhqnU6hnk-54on7Q' },
    { id: 9, name: 'Salman Lala', role: 'Marketing Manager', location: 'Punjab', image: 'https://pbs.twimg.com/profile_images/1572232043081404416/wsDtVwr2_400x400.jpg' },
    { id: 10, name: 'Jojo Z', role: 'Chinese Dubbing Specialist', location: 'Hong Kong', image: 'https://fiverr-res.cloudinary.com/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/236827303/original/b393f883f78cb86cc12ddb724e374ea0c73ef501.jpg' },
    { id: 11, name: 'Miguel Oliver', role: 'Spanish Dubbing Specialist', location: 'Barcelona', image: 'https://fiverr-res.cloudinary.com/videos/t_main1,q_auto,f_auto/x8lu6hpb8j9qamg3kzqo/record-and-edit-your-professional-spanish-voiceover.png' },
    { id: 12, name: 'Katia F', role: 'French Dubbing Specialist', location: 'Marseille', image: 'https://fiverr-res.cloudinary.com/image/upload/t_profile_original,q_auto,f_auto/v1/attachments/profile/photo/694ef9068692959849e6239644a0e892-1570022465900/fe5a33db-7473-4a6d-aa01-24cef81c110f.jpg' },
    { id: 13, name: 'Maison George', role: 'German Dubbing Specialist', location: 'Berlin', image: 'https://fiverr-res.cloudinary.com/videos/so_1.696986,t_main1,q_auto,f_auto/s3lulvwe8jfkxq8hbvmz/record-a-sympathic-german-voice-over.png' },
    { id: 14, name: 'Laura', role: 'Italian Dubbing Specialist', location: 'Rome', image: 'https://fiverr-res.cloudinary.com/videos/t_main1,q_auto,f_auto/xhcr0xzcrqsx30uj9y8s/italian-voiceover-speaker-dubber.png' },
    { id: 15, name: 'Karina', role: 'Russian Dubbing Specialist', location: 'Saint Petersburg', image: 'https://fiverr-res.cloudinary.com/image/upload/t_profile_original,q_auto,f_auto/v1/attachments/profile/photo/a6f2a0e0c838883051279dd2ed92d0e1-1010319131705835447.753396/38D7320E-EE01-4728-93B7-7C5DDF0F5F09' },
    { id: 16, name: 'Miho\n', role: 'Japanese Dubbing Specialist', location: 'Osaka', image: 'https://fiverr-res.cloudinary.com/image/upload/t_profile_original,q_auto,f_auto/v1/attachments/profile/photo/04e5a5adb9ca3480fa26d67a8266ce6d-1696991943778/fd65a689-60dc-4c80-96f3-32eb68d72d28.jpg' },
    { id: 17, name: 'Thanakrit Voice', role: 'Thai Dubbing Specialist', location: 'Bangkok', image: 'https://fiverr-res.cloudinary.com/image/upload/t_profile_original,q_auto,f_auto/v1/attachments/profile/photo/195e983898eea4a6ff4b2ec5dca4a00b-1526635111699704396.827056/7CA803B7-4C08-439C-BAA0-6CB94BAA5708' },
    { id: 18, name: 'Jihye S\n', role: 'Korean Dubbing Specialist', location: 'Busan', image: 'https://fiverr-res.cloudinary.com/image/upload/t_profile_original,q_auto,f_auto/v1/attachments/profile/photo/2c407e26587a0205aede7ff85be81378-1661843382293/5b9e6cae-59ec-4871-b8f0-68ef45c06e34.jpg' },
    { id: 19, name: 'Arpit Bhatnagar', role: 'Hindi Dubbing Specialist', location: 'Mumbai', image: 'https://fiverr-res.cloudinary.com/image/upload/t_profile_original,q_auto,f_auto/v1/attachments/profile/photo/f5060b096479b219ace8b486bea3ad58-1724352522042/49a40bda-cde7-49ac-bbc8-0cea95058754.png' },
    { id: 20, name: 'Ahmed Salem\n', role: 'Arabic Dubbing Specialist', location: 'Riyadh', image: 'https://fiverr-res.cloudinary.com/image/upload/t_profile_original,q_auto,f_auto/v1/attachments/profile/photo/2e0b88be5201ea95ea4bdc70629c068e-1694185736676/283e49ec-56bf-4e79-a3e4-c61ef7acbde2.jpg' },
    { id: 21, name: 'Diego Luiz', role: 'Portuguese Dubbing Specialist', location: 'Lisbon', image: 'https://fiverr-res.cloudinary.com/image/upload/t_profile_original,q_auto,f_auto/v1/attachments/profile/photo/6707f744dfe77aa9524b4c13997f1a39-1582911733478/d0b6dd23-0fd3-4401-a740-ddc1fc685a76.jpg' },
    { id: 22, name: 'Ha Thi Kim Chi', role: 'Vietnamese Dubbing Specialist', location: 'Hanoi', image: 'https://fiverr-res.cloudinary.com/t_main1,q_auto,f_auto,q_auto,f_auto/gigs2/378807248/original/30cbe46bb193ea6bd5390f71b192fc4cfe6b04fc.jpg' },
    { id: 23, name: 'Yushar Azrian\n', role: 'Indonesian Dubbing Specialist', location: 'Jakarta', image: 'https://fiverr-res.cloudinary.com/t_gig_cards_web,q_auto,f_auto/gigs/163196598/original/50d58f207388274aae7f4a4b10ec2a214ad3d370.png' },
    { id: 24, name: 'Gulsah Cobanogl', role: 'Turkish Dubbing Specialist', location: 'Ankara', image: 'https://fiverr-res.cloudinary.com/videos/t_main1,q_auto,f_auto/u75tusrsjhp3vym2cxpw/record-a-turkish-female-voice-over.png' },
    { id: 25, name: 'Imroz', role: 'Bangla Dubbing Specialist', location: 'Chattogram', image: 'https://fiverr-res.cloudinary.com/t_gig_cards_web,q_auto,f_auto/gigs/237690912/original/105e9c7fbaff041c6acb766ff716ec1e314756d5.jpg' },
];

const TeamMember = ({ member }) => (
    <div className="team-member bg-white rounded-lg shadow-md p-4 flex items-center space-x-4">
        <div className="profile-pic flex-shrink-0">
            <img src={member.image || defaultImage} alt={member.name} className="w-16 h-16 rounded-full object-cover" />
        </div>
        <div className="member-info text-left">
            <h3 className="text-lg font-semibold text-gray-800">{member.name}</h3>
            <p className="text-sm text-gray-600">{member.role}</p>
            <p className="text-xs text-gray-500">{member.location}</p>
        </div>
    </div>
);

const ScrollingRow = ({ members, direction, speed }) => {
    const [isPaused, setIsPaused] = useState(false);

    return (
        <div
            className={`scrolling-row ${direction} ${isPaused ? 'paused' : ''} overflow-hidden my-8`}
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
        >
            <div
                className="scroll-content flex"
                style={{ animationDuration: `${speed}s` }}
            >
                {members.concat(members).map((member, index) => (
                    <div key={`${member.id}-${index}`} className="flex-shrink-0 mx-4">
                        <TeamMember member={member} />
                    </div>
                ))}
            </div>
        </div>
    );
};

const TeamSection = () => {
    const rowMembers = [
        teamMembers.slice(0, 7),
        teamMembers.slice(8, 16),
        teamMembers.slice(17, 25),
    ];

    return (
        <section className="section-plum-faq text-white py-16 px-4 " id="team">
            <div className="mx-auto ">
                <h1 className="text-4xl font-bold text-center mb-6">Meet Our Team</h1>
                <p className="text-center text-xl mb-12 max-w-2xl mx-auto text-blue-100">
                    At Dubbigo, our dedicated team is here to ensure high-quality dubbing in various languages, making content accessible to audiences worldwide.
                </p>
                <ScrollingRow members={rowMembers[0]} direction="right-to-left" speed={75} />
                <ScrollingRow members={rowMembers[1]} direction="left-to-right" speed={70} />
                <ScrollingRow members={rowMembers[2]} direction="right-to-left" speed={85} />
            </div>
        </section>
    );
};

export default TeamSection;
