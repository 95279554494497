import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import './Faq.css';


const FAQItem = ({ question, answer, isOpen, onClick }) => (
    <div className="faq-item" >
        <button className="faq-question-button" onClick={onClick}>
            <h3 className="faq-question">{question}</h3>
            <ChevronDown className={`faq-icon ${isOpen ? 'open' : ''}`} />
        </button>
        <div className={`faq-answer ${isOpen ? 'open' : ''}`}>
            <div className="faq-answer-content">
                <p>{answer}</p>
            </div>
        </div>
    </div>
);

const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(0);

    const faqData = [
        {
            question: "Can you manage my social media channels?",
            answer: "Yes, we offer full channel/page management, including SEO optimization, content posting, and ad collaboration, where we can handle deals with advertisers on your behalf."
        },
        {
            question: "What platforms do you support for content management?",
            answer: "Currently, we provide management services for YouTube, Facebook, and Instagram, with more platforms being added soon."
        },
        {
            question: "How do you handle ad promotions on my channel/page?",
            answer: "Our team specializes in ad promotions, where we attract and finalize advertising deals on your behalf, ensuring maximum visibility and revenue for your content."
        },
        {
            question: "How do you ensure the quality of AI voice dubbing?",
            answer: "Our AI dubbing technology ensures accuracy, but we also provide human quality checks to make sure the final output sounds natural and professional."
        },
        {
            question: "What files are required to start a dubbing project?",
            answer: "To begin, we need the raw video, audio files, thumbnails, and any project files if there are multiple speakers. This ensures seamless dubbing and proper coordination across your content."
        },
        {
            question: "How do I get started with your services?",
            answer: "Simply fill out the contact form below, and we will get in touch with you to discuss your needs and how we can help you grow your content."
        },
    ];

    return (
        <div className={"faq-container1 section-plum"}>
            <div className="faq-container2" id={"faq"}>
                <h2 className="faq-title">FAQ</h2>
                <div>
                    {faqData.map((item, index) => (
                        <FAQItem
                            key={index}
                            question={item.question}
                            answer={item.answer}
                            isOpen={index === openIndex}
                            onClick={() => setOpenIndex(index === openIndex ? -1 : index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FAQ;